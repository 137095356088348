<template>

<div class="col-12"> 


     <h3>ID: {{ device.node_id }} の キャリブレーション</h3>

設置位置 高さ x  ((キャリブレーション前の計測距離-計測距離 )/キャリブレーション前の計測距離 ) 

      <br>
      <br>
      <label> 設置位置 高さ</label><br>
      <input v-model="height" style="width:300px;" type="number" min="1" placeholder="高さ">(mm) <br><br>
      
      <label> キャリブレーション前の計測距離</label><br>
      <input v-model="distMax" style="width:300px;" type="number" min="1" placeholder="キャリブレーション前の計測距離">(mm)<br><br>
       <button class="btn btn-primary"  v-on:click="setCalibration()">設定する</button> 
       <br>
       <br><small>※ 設定の反映は最大30分かかります。</small>     
       <br>
       <br>
       <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>計測距離(mm)</th>
              <th>積雪高さ(mm)</th>
               <th>エラーコード</th>
              <th>データ取得時間</th>
            </tr>
          </thead>

          <tbody>
          
              <tr v-for="(row, key) in data" :key="key">
                <td>
                 <span >{{ row.data1}}</span >
                </td>
                <td>
                 <span >{{ cali(row.data1) }}</span >
                </td>
                <td>
                 <span >{{ row.alive }}</span >
                </td>
                <td>
                 <span >{{
                     moment(  new Date( Number(row.datetime) ) , 'YYYY/MM/DD, hh:mm:ss A')  }}</span >
                </td>
            </tr>
          </tbody>
        </table>
        
</div>

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import axios from 'axios';

export default defineComponent({
  name: "SnowDevice",
   data : function() {return { 
     device : {},
     data : {},
     height: 0,
     distMax: 0
  } } , 
  /*, computed: {
  },*/
  mounted : async function(){

       this.device = this.$route.params;
       let node_id = this.$route.params.node_id ;
       var de = await axios.get("/api/v4/sensors/?node_id="+ node_id );
     
       this.device =  de.data.data.pop();

       var da = await axios.get("/api/v4/sensors/data/"+ node_id + "/?top=1&select=data1,alive,datetime");
       
       this.data = da.data.data; 
      
      try{
        if(this.device.calibration){
           // (function(){ var r = 0 * ( 0 - [1] ) / 0; return ( r < 0)?0: r; })()
            
          let d = this.device.calibration.split("(function(){ var r = ")[1].split(" ");
          this.height = Number(d[0].trim());       
          this.distMax = Number(d[3].trim());
       }
      }catch(e){console.log(e);}
     
         setInterval( async function(){
            var da = await axios.get("/api/v4/sensors/data/"+ node_id + "/?top=1&select=data1,alive,datetime");
            this.data = da.data.data;
        }.bind(this), 6000);
    
   },
  methods: {
       moment(date, format) {
           
        return moment(date).local().format(format)
        } ,
        cali(dist){
         const r = this.height * ( (this.distMax- Number(dist) )/ this.distMax) ;
         
         this.cdata =  (r<0)?0:r;
            
            return this.cdata;
        },
       setCalibration:async   function(){
     
              var r = await axios.put("/api/v4/devices/snow/"+ this.device.node_id + "/calibration" ,
              {
                  height : this.height,
                  distance:this.distMax
              });
              if(r.data.error === 0){
                  this.$emit( 'show-flash', {"message":"設定しました。","type": "success"}); 
              } else{
                    this.$emit( 'show-flash', {"message":"設定に失敗しました。","type": "warning"});
                  
              }
        }
    }
  }
  );
//1810 2679
</script>

<style>

dd{
    display: inline-block;
}

</style>
